import React from 'react';
import Image from 'next/image';
import { useAccount } from 'wagmi';
import { ConnectButtonWrapper } from '../Web3WalletProvider';

const MetamaskCompLogin = () => {
  const { isConnected } = useAccount();

  if (!isConnected)
    return (
      <ConnectButtonWrapper>
        <button className='js-wallet mb-4 flex w-full items-center justify-center rounded-full border-2 border-transparent bg-accent py-4 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'>
          <Image
            src='/images/wallets/metamask_24.svg'
            className='inline-block h-6 w-6'
            alt=''
            height={24}
            width={24}
          />
          <span className='ml-2.5'>Sign in with Metamask</span>
        </button>
      </ConnectButtonWrapper>
    );

  if (isConnected)
    return (
      <button className='js-wallet mb-4 flex w-full items-center justify-center rounded-full border-2 border-transparent bg-accent py-4 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'>
        <Image
          src='/images/wallets/metamask_24.svg'
          className=' inline-block h-6 w-6'
          alt=''
          height={24}
          width={24}
        />
        <span className='ml-2.5'>Sign in with Metamask</span>
      </button>
    );
};

const ConfirmCheckout = ({ checkbox, handleBuy, buttonText = 'Confirm Checkout' }) => {
  const { isConnected } = useAccount();

  if (!checkbox) {
    return <div>Accept terms</div>;
  }

  if (!isConnected)
    return (
      <ConnectButtonWrapper>
        <button
          type='button'
          className='rounded-xl bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'
        >
          Connect Your Wallet
        </button>
      </ConnectButtonWrapper>
    );

  if (isConnected)
    return (
      <button
        type='button'
        className={`${
          buttonText !== 'Confirm Checkout' && 'opacity-40'
        } rounded-xl bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark`}
        onClick={handleBuy}
        disabled={buttonText !== 'Confirm Checkout'}
      >
        {buttonText}
      </button>
    );
};

const MetamaskCompIcon = ({ ...props }) => {
  const { isConnected } = useAccount();

  if (!isConnected)
    return (
      <ConnectButtonWrapper>
        <button
          className={
            'js-wallet group flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
          }
          // onClick={() => dispatch(walletModalShow())}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className={
              'h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
            }
          >
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z' />
          </svg>
        </button>
      </ConnectButtonWrapper>
    );

  if (isConnected)
    return (
      <div>
        <ConnectButtonWrapper>
          <button
            className={
              'js-wallet group flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
            }
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width='24'
              height='24'
              className={
                'h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
              }
            >
              <path fill='none' d='M0 0h24v24H0z' />
              <path d='M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z' />
            </svg>
          </button>
        </ConnectButtonWrapper>
      </div>
    );
};

export { MetamaskCompIcon, MetamaskCompLogin, ConfirmCheckout };
