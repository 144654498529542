import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { getSupportedChain, supportedChains } from '../../../helpers/getSupportedChain';
import { getAddress } from 'ethers/lib/utils';

const UWLockedBadge = ({ item }) => {
  if (!item) return null;

  if (item.collection.contractType !== 'ERC721') return null;

  const supportedChain = getSupportedChain(item.ownerObject.chain);
  if (!supportedChain) return null;
  const vaultAddress = supportedChain.uwVault;

  if (getAddress(item.ownerObject.owner) !== getAddress(vaultAddress)) return null;

  return (
    <div
      style={{
        //filter: `grayscale(${rarityRatio}%)`,
        background: 'linear-gradient(to right, #A0A0A0, #A0A0A0)',
      }}
      className={`via-red-500 absolute left-3 bottom-3 z-[1] flex min-w-[50px] cursor-pointer items-center justify-between rounded-lg border-jacarta-100  px-2 py-1 text-white transition-all group-hover/price:bg-accent dark:text-[#FFF]`}
    >
      <div className='ml-[2px] font-display text-sm'>
        <div>Locked By</div>
        <p className='text-xs'>Universal Wrapper</p>
      </div>
    </div>
  );
};

export default UWLockedBadge;
